import { AutoStories } from '@mui/icons-material';
import {
  Box,
  Collapse,
  IconButton,
  Paper,
  Tooltip,
  Typography,
  useTheme
} from '@mui/material';
import { useState } from 'react';

function Legend() {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [fullWidth, setFullWidth] = useState(false);

  const handleClick = () => {
    if (open) { // closing
      setOpen(!open);
      setTimeout(() => {
        setFullWidth(false);
      }, 230);
    } else { // opening
      setFullWidth(true);
      setTimeout(() => {
        setOpen(!open);
      }, 230);
    }
  }

  return (
    <Paper elevation={5} sx={{
      padding: theme.spacing(2),
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      width: fullWidth ? '100%' : '40px',
      height: '100%',
      overflow: 'auto',
      maxWidth: '380px',
      minWidth: fullWidth ? '200px' : '40px',
      maxHeight: `calc(90vh - (${theme.spacing(6)} + 64px))`,
      minHeight: fullWidth ? '220px' : '40px',
      transition: 'width 0.3s, min-width 0.3s, min-height 0.3s',
    }}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Collapse in={open}>
          <Typography variant='h5'>
            Legend
          </Typography>
        </Collapse>
        <Tooltip title="Toggle legend" sx={{ marginLeft: 'auto' }}>
          <IconButton onClick={handleClick}>
            <AutoStories />
          </IconButton>
        </Tooltip>
      </Box>
      <Collapse in={open}>
        <Box>
          <Typography gutterBottom variant="subtitle1" component="div" fontWeight={'bold'}>
            Payout Percentage
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: theme.spacing(0, 5) }}>
            <img alt='Not Found' src='/icons/cycloneLegendImage1.png' width="100%" height="80%" />
          </Box>
          <Typography gutterBottom variant="subtitle2" align='center' component="div" fontStyle={'italic'}>
            Proximity to province vs hurricane category
          </Typography>
          <Typography variant="body2" >
            The payout percentage is determined by proximity of the cyclone to the insured province, and the intensity of the cyclone, as measured by the Saffir-Simpson hurricane category (1-5).
          </Typography>
        </Box>
        <Box sx={{ marginTop: theme.spacing(2) }}>
          <Typography gutterBottom variant="subtitle1" component="div" fontWeight={'bold'}>
            Saffir–Simpson Hurricane Categories
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', padding: theme.spacing(0, 5) }}>
            <img alt='Not Found' src='/icons/hurricaneLegend.png' width="100%" height="80%" />
          </Box>
        </Box>
      </Collapse>
    </Paper>
  );
}

export default Legend;
import {
  makeStyles,
  createStyles,
  Theme,
  Paper,
  Color,
} from '@material-ui/core';
import { Circle, MapContainer, Pane, TileLayer, ZoomControl } from 'react-leaflet';
import InteractiveMultiPolygon from './interactiveMultiPolygon';
import { LatLng, latLng, latLngBounds, LatLngExpression, Map } from 'leaflet';
import HomeControl from './homeControl';
import { ReactElement, useEffect, useMemo, useState } from 'react';
const wkt = require('wkt');

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      width: '100%',
      height: '100%'
    },
    mapContainer: {
      height: '100%',
      width: '100%',
      '& a.MuiButton-containedSecondary': {
        color: '#F6F6F8'
      }
    }
  }),
);

interface Props {
  center: number[];
  zoom: number;
  maxBounds: number[][];
  maxZoom: number;
  minZoom: number;
  provinces: { id: number, name: string, multiPolygon: { type: string, coordinates: any[] }, color: Color | string, popup?: ReactElement, popupMinSize?: number }[];
  resize?: boolean;
  showBorders?: boolean;
  cyclonePoints?: { coordinate: string[], color: string[] };
}

interface Point {
  id: number,
  center: LatLngExpression,
  color?: string,
  radius: number
}

function InteractiveMap({
  center: _center,
  zoom,
  maxBounds: _maxBounds,
  maxZoom,
  minZoom,
  provinces,
  resize,
  showBorders,
  cyclonePoints
}: Props) {
  const classes = useStyles();
  const [map, setMap] = useState<Map>();

  const center = useMemo(() => latLng(_center[0], _center[1]), [_center]);
  const maxBounds = useMemo(() => latLngBounds(latLng(_maxBounds[0][0], _maxBounds[0][1]), latLng(_maxBounds[1][0], _maxBounds[1][1])), [_maxBounds]);

  const [circles, setCircles] = useState<{ id: number, center: LatLngExpression, color: string, radius: number }[]>([]);

  useEffect(() => {
    map?.setView(center, map.getZoom());
  }, [center, map]);
  useEffect(() => {
    map?.setMaxBounds(maxBounds);
  }, [maxBounds, map]);
  useEffect(() => {
    map?.setMaxZoom(maxZoom);
  }, [maxZoom, map]);
  useEffect(() => {
    map?.setMinZoom(minZoom);
  }, [minZoom, map]);
  useEffect(() => {
    map?.setZoom(zoom);
  }, [zoom, map]);

  useEffect(() => {
    map?.invalidateSize();
  }, [resize, map]);

  useEffect(() => {
    const wktCoordinatesArray = cyclonePoints?.coordinate || [];
    const colorArray = cyclonePoints?.color || [];
    const length = colorArray.length;
    const _circles = [];
    for (let i = 0; i < length; i++) {
      const cyclonePoint = wkt.parse(wktCoordinatesArray[i]);

      const cyclonePointCoordinates = cyclonePoint.coordinates;

      const x = cyclonePointCoordinates[0];
      const y = cyclonePointCoordinates[1];

      const color = colorArray[i];

      _circles.push({
        id: i,
        center: latLng(y, x),
        color,
        radius: 10000
      });
    }
    setCircles(_circles);
  }, [cyclonePoints])

  return (
    <Paper className={classes.paper}>
      <MapContainer
        center={center}
        zoom={zoom}
        maxBounds={maxBounds}
        maxBoundsViscosity={0.8}
        maxZoom={maxZoom}
        minZoom={minZoom}
        doubleClickZoom={false}
        className={classes.mapContainer}
        whenCreated={(m) => {
          setMap(m);
        }}
        zoomControl={false}
      >
        <ZoomControl position='bottomleft' />
        <HomeControl center={center} zoom={zoom} />
        <TileLayer
          url="https://{s}.basemaps.cartocdn.com/light_nolabels/{z}/{x}/{y}.png"
        />
        <Pane name='labels' style={{ zIndex: 600, pointerEvents: 'none' }}>
          <TileLayer
            url="https://{s}.basemaps.cartocdn.com/light_only_labels/{z}/{x}/{y}.png"
            attribution='©OpenStreetMap, ©CartoDB'
          />
        </Pane>
        {provinces.map(province => {
          if (province.multiPolygon) {
            return <InteractiveMultiPolygon
              key={province.id}
              color={province.color}
              multiPolygon={province.multiPolygon}
              popup={province.popup}
              popupMinSize={province.popupMinSize}
              tooltip={province.name}
              showBorders={showBorders}
            />
          }
        })}

        {circles.map(
          c => { return <Circle pane='markerPane' key={c.id} center={c.center} radius={c.radius} color={c.color} /> })
        }
      </MapContainer>
    </Paper>
  );
}

export default InteractiveMap;

import { useState, createContext, ReactElement } from 'react';
import * as countriesConfigurations from '../configurations/countriesConfigurations.json';

interface Context {
  country: string;
  setCountry: (country: string) => void;
  currency: string;
  setCurrency: (currency: string) => void;
}

const CountryContext = createContext({} as Context);

interface CountryContextProviderProps {
  initialCountry: string;
  children: ReactElement | ReactElement[];
}

function CountryContextProvider({ initialCountry, children }: CountryContextProviderProps) {
  const [country, setCountry] = useState<string>(initialCountry);
  const configuredCountry = countriesConfigurations.countries.find(c => c.code === country);
  const [currency, setCurrency] = useState<string>(configuredCountry ? configuredCountry.currency : '$');
  
  const context: Context = {
    country,
    setCountry,
    currency,
    setCurrency
  };

  return (
    <CountryContext.Provider value={context}>
      {children}
    </CountryContext.Provider>
  );
};

export default CountryContext;
export { CountryContextProvider };
import { GoogleLoginResponse } from "react-google-login";

const BASE_PATH = process.env.REACT_APP_API_BASE_URL;

export class ApiError extends Error {
  statusCode: number;
  constructor(message: string, statusCode: number) {
    super(message);
    this.statusCode = statusCode;
  }
}

class Api {

  tokenId: string | null = null;

  setTokens(res: GoogleLoginResponse) {
    this.tokenId = res.tokenObj.id_token;
    this.refreshTokenSetup(res);
  };

  removeTokens() {
    this.tokenId = null;
  };

  refreshTokenSetup(res: GoogleLoginResponse) {
    let refreshTiming = (res.tokenObj.expires_in || 3600  - 5 * 60) * 1000;
    const refreshToken = async () => {
      const newAuthRes = await res.reloadAuthResponse();
      refreshTiming = (newAuthRes.expires_in || 3600  - 5 * 60) * 1000;
      this.tokenId = newAuthRes.id_token;
      setTimeout(refreshToken, refreshTiming);
    };
    setTimeout(refreshToken, refreshTiming);
  };

  get(path: string): Promise<any> {
    return this.fetch('GET', path);
  };

  getBinary(path: string): Promise<any> {
    return fetch(
      `${BASE_PATH}${path}`,
      {
        method: 'GET',
        headers: this.getHeaders(),
      }
    ).then((response) => response.arrayBuffer());
  };

  post(path: string, body: any): Promise<any> {
    return this.fetch('POST', path, body);
  };

  postBinary(path: string, file: File): Promise<any> {
    const data = new FormData();
    data.append("file", file);
    return fetch(
      `${BASE_PATH}${path}`,
      {
        method: 'POST',
        headers: this.getHeaders(false),
        body: data
      }
    )
      .then((res) => {
        if (res.status >= 200 && res.status < 300) return res.json();
        return res.json().then(({ statusCode, message }) => {
          throw new ApiError(message, statusCode);
        });
      });
  };

  put(path: string, body: any): Promise<any> {
    return this.fetch('PUT', path, body);
  };

  delete(path: string): Promise<any> {
    return this.fetch('DELETE', path);
  };

  getStoreRefreshToken(): string | null {
    return window.localStorage.getItem('refreshToken');
  };

  private async fetch(method: string, path: string, body?: any, unauthenticated: boolean = false): Promise<any> {
    return fetch(
      `${BASE_PATH}${path}`,
      {
        method,
        headers: this.getHeaders(true, unauthenticated),
        body: body ? JSON.stringify(body) : null,
      }
    ).then((res) => {
      if (res.status >= 200 && res.status < 300) return res.json();
      return res.json().then(({ statusCode, message }) => {
        throw new ApiError(message, statusCode);
      });
    });
  };

  private getHeaders(withContentType: boolean = true, unauthenticated: boolean = false): Headers {
    const headers = new Headers();
    if (withContentType)
      headers.append('Content-Type', 'application/json');
    if (this.tokenId && !unauthenticated)
      headers.append('Authorization', `Bearer ${this.tokenId}`);
    return headers;
  };

}

export default new Api();

import PrecipitationModel from '../models/Precipitation';

import { Line } from 'react-chartjs-2';

import {
  Box
} from '@mui/material';
import { ChartData, ChartOptions } from 'chart.js';
import 'chartjs-adapter-moment';
import moment from 'moment';

const buildDatasets = (
  precipitations: PrecipitationModel[],
  red_trigger: number,
  orange_trigger: number,
  yellow_trigger: number,
  min_date: number | undefined,
  max_date: number | undefined
): { data: ChartData<'line'>, options: ChartOptions<'line'> } => ({
  data: {
    datasets: [
      {
        label: 'precipitations',
        borderColor: '#0000D7',
        backgroundColor: '#0000D720',
        data: precipitations.map(p => {
          return { x: moment(p.date).valueOf(), y: Math.round(parseFloat(p.value)) }
        }),
        fill: true,
        pointRadius: 0,
        pointHitRadius: 5,
      },
      {
        label: 'red trigger',
        borderColor: '#C92519',
        backgroundColor: '#C92519',
        pointRadius: 0,
        pointHitRadius: 0,
        data:  [
          { x:  min_date ? min_date : precipitations.length > 0 ? moment(precipitations[0].date).valueOf() : moment().valueOf(), y: red_trigger },
          { x: max_date ? max_date : precipitations.length > 0 ? moment(precipitations[precipitations.length - 1].date).valueOf() : moment().valueOf(), y: red_trigger }
        ]
      },
      {
        label: 'orange trigger',
        borderColor: '#FF9900',
        backgroundColor: '#FF9900',
        pointRadius: 0,
        pointHitRadius: 0,
        data: [
          { x:  min_date ? min_date : precipitations.length > 0 ? moment(precipitations[0].date).valueOf() : moment().valueOf(), y: orange_trigger },
          { x: max_date ? max_date : precipitations.length > 0 ? moment(precipitations[precipitations.length - 1].date).valueOf() : moment().valueOf(), y: orange_trigger }
        ]
      },
      {
        label: 'yellow trigger',
        borderColor: '#FFE600',
        backgroundColor: '#FFE600',
        pointRadius: 0,
        pointHitRadius: 0,
        data:  [
          { x:  min_date ? min_date : precipitations.length > 0 ? moment(precipitations[0].date).valueOf() : moment().valueOf(), y: yellow_trigger },
          { x: max_date ? max_date : precipitations.length > 0 ? moment(precipitations[precipitations.length - 1].date).valueOf() : moment().valueOf(), y: yellow_trigger }
        ]
      }
    ]
  },
  options: {
    maintainAspectRatio: false,
    scales: {
      x: {
        type: 'time',
        time: {
          unit: 'day',
          stepSize: 1,
          displayFormats: {
            'day': 'DD MMM YYYY'
          },
          tooltipFormat: 'DD MMM YYYY'
        },
        min: min_date,
        max: max_date
      },
      y: {
        min: 0
      }
    },
    plugins: {
      legend: {
        display: false
      },
      tooltip: {
        displayColors: false,
        callbacks: {
          label: function (context) {
            var label = context.dataset.label || '';
            if (label) {
              label += ': ';
            }
            if (context.parsed.y !== null) {
              label += context.parsed.y + " mm";
            }
            return label;
          }
        }
      }
    }
  }
});

interface Props {
  precipitations: PrecipitationModel[];
  red_trigger: number;
  orange_trigger: number;
  yellow_trigger: number;
  min_date?: number;
  max_date?: number;
}

function PrecipitationChart({ precipitations, red_trigger, orange_trigger, yellow_trigger, min_date = undefined, max_date = undefined }: Props) {
  const { data, options } = buildDatasets(precipitations, red_trigger, orange_trigger, yellow_trigger, min_date, max_date);

  return (
    <Box width={'100%'} height={'100%'}>
      <Line
        data={data}
        options={options}
      />
    </Box>
  )
};

export default PrecipitationChart;

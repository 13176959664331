import ApiService from './Api';
import CycloneData from '../models/CycloneData';

class GADM {

  getGADMs(): Promise<any> {
    return ApiService.get(`/gadms`);
  }

  getGeometriesByCountry(countryCode: string, type: string): Promise<{ geometry: string, name: string, provinceId: string }[]> {
    return ApiService.get(`/gadms/country/${countryCode}/${type}`);
  }

  getGeometriesByCyclone(cyclone: string, countryCode: string): Promise<{ gadms: { geometry: string, GID: string }[], cycloneData: CycloneData }> {
    return ApiService.get(`/gadms/cyclone/${cyclone}/${countryCode}`);
  }

}

export default new GADM();

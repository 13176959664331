import { useContext } from 'react';

import ApiService from '../services/Api';
import AuthContext from '../contexts/AuthContext';
import { GoogleLoginResponse } from 'react-google-login';

function useAuth() {
  const {
    isAuthenticated,
    setUserId
  } = useContext(AuthContext);

  const onLogin: (res: GoogleLoginResponse, userId: string) => void = (res, userId) => {
    ApiService.setTokens(res);
    setUserId(userId);
  };

  return {
    isAuthenticated,
    onLogin,
  };
}

export default useAuth;
import { useState } from 'react';
import {
  Container,
  Box,
  Snackbar,
} from '@mui/material';
import { useTheme } from "@mui/system";
import GoogleLogin, { GoogleLoginResponse, GoogleLoginResponseOffline } from 'react-google-login';

import useAuth from '../hooks/auth';
import AuthenticateService from '../services/Authenticate';

function Login() {
  const theme = useTheme();
  const { onLogin } = useAuth();
  // for snackbar
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');

  const responseGoogle = (response: GoogleLoginResponse | GoogleLoginResponseOffline) => {
    if ('tokenId' in response) {
      AuthenticateService.login(response.tokenObj.id_token).then((userId) => {
        onLogin(response, userId);
      }).catch((err) => {
        setMessage(err.message);
        setOpen(true);
      });
    }
  }

  const failureGoogle = (response: any) => {
    console.log(response);
    setMessage('Unauthorized access. Please contact administrators.');
    setOpen(true);
  }

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  return (
    <Container maxWidth="sm">
      <Box mt={3} display="flex" flexDirection="column" alignItems="center">
        <img
          alt="IBISA Showroom"
          src='/logo_ibisa_black.png'
          style={{ width: '50%' }}
        />
        <Box mt={1} sx={{ marginTop: theme.spacing(6) }}>
          <GoogleLogin
            clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID ? process.env.REACT_APP_GOOGLE_CLIENT_ID : ''}
            buttonText="Login"
            onSuccess={responseGoogle}
            onFailure={failureGoogle}
            cookiePolicy={'single_host_origin'}
            isSignedIn={true}
          />
        </Box>
      </Box>
      <Snackbar
        ContentProps={{
          sx: {
            textAlign: 'center',
            display: 'block',
            backgroundColor: '#f44336',
          }
        }}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        message={message}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      />
    </Container>
  );
}

export default Login;
import { ReactElement } from 'react';
import { useTheme } from "@mui/system";

import Drawer from './drawer/AppBar';
import { Box } from '@mui/material';

interface LayoutProps {
  children: ReactElement;
}

function Layout({ children }: LayoutProps) {
  const theme = useTheme();

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      backgroundColor: theme.palette.background.default
    }}>
      <Drawer />
      <Box sx={{
        padding: theme.spacing(3, 6, 6, 6),
        height: 'calc(100% - 64px)',
        boxSizing: 'border-box'
      }}>
        {children}
      </Box>
    </Box>
  );
}

export default Layout;
import ApiService from './Api';

class Authenticate {

  login(tokenId: string):Promise<string> {
    return ApiService
      .post(
        '/auth/login',
        { tokenId }
      );
  }

  logout() {
    ApiService.removeTokens();
  }
  
}

export default new Authenticate();

import {
  Box,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  Backdrop,
  CircularProgress
} from '@mui/material';

import Page from '../components/Page';
import { useState } from 'react';
import Legend from '../components/legend/Legend';

import { useEffect } from 'react';
import GADMS from '../services/GADMS';
import { useTheme } from "@mui/system";
import { Color } from '@material-ui/core';
import useCountry from '../hooks/country';
import * as countriesConfigurations from '../configurations/countriesConfigurations.json';
import InteractiveMap from '../components/InteractiveMap/InteractiveMap';

const wkt = require('wkt');

interface DataProvinces {
  id: number;
  name: string;
  multiPolygon: { type: string, coordinates: any[] };
  color: Color | string;
}

function convertToMultiPolygon(geometry: any) {
  const geo = wkt.parse(geometry);
  if (geo.type === "Polygon") {
    return {
      type: "MultiPolygon",
      coordinates: [geo.coordinates]
    };
  }
  else {
    return geo;
  }
}

function Cyclone() {
  const theme = useTheme();
  const [isLoading, setIsLoading] = useState(false)

  const [provinceAreas, setProvinceAreas] = useState<DataProvinces[]>([]);
  const [cyclones, setCyclones] = useState<string[]>([]);
  const [cyclone, setCyclone] = useState<any>();
  const [selectedCyclone, setSelectedCyclone] = useState<string>();

  const { country } = useCountry();
  const [countryConfig, setCountryConfig] = useState(countriesConfigurations.countries[0]);

  useEffect(() => {
    const _countryConfig = countriesConfigurations.countries.find(c => c.code === country) || countriesConfigurations.countries[0];
    setCountryConfig(_countryConfig);
    setCyclones(_countryConfig.map.cyclone_name);
    setSelectedCyclone(_countryConfig.map.cyclone_name[0]);
  }, [country]);

  useEffect(() => {
    if (selectedCyclone !== undefined) {
      setIsLoading(true);
      GADMS.getGeometriesByCyclone(selectedCyclone, country).then((data) => {
        setCyclone(data.cycloneData);
        setProvinceAreas(data.gadms.map((gadm, index) => ({
          id: index,
          name: data.cycloneData.damage_data.name_full
            ? data.cycloneData.damage_data.name_full[data.cycloneData.damage_data.name.indexOf(gadm.GID)]
            : gadm.GID,
          multiPolygon: convertToMultiPolygon(gadm.geometry),
          color: data.cycloneData.damage_data.color[data.cycloneData.damage_data.name.indexOf(gadm.GID)]
        })));
        setIsLoading(false);
      });
    }
  }, [selectedCyclone]);

  return (
    <Page title="Cyclone protection">
      <Box sx={{
        position: 'absolute',
        top: '145px',
        left: theme.spacing(6),
        zIndex: 2,
        boxShadow: '0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 5px 8px 0px rgb(0 0 0 / 14%), 0px 1px 14px 0px rgb(0 0 0 / 12%)'
      }}>
        <FormControl>
          <InputLabel id="selected-cyclone">Cyclone</InputLabel>
          <Select
            labelId="select-label"
            label="Cyclone"
            autoWidth
            value={selectedCyclone ? cyclones.indexOf(selectedCyclone) : ''}
            onChange={(evt: any) => setSelectedCyclone(cyclones[evt.target.value])}
            sx={{ backgroundColor: theme.palette.background.paper }}
          >
            {cyclones.map(cyclone => (
              <MenuItem value={cyclones.indexOf(cyclone)} key={cyclones.indexOf(cyclone)}>{cyclone}</MenuItem>
            ))}

          </Select>
        </FormControl>
      </Box>
      <Box sx={{
        position: 'absolute',
        top: `calc(${theme.spacing(6)} + 64px)`,
        right: theme.spacing(6),
        zIndex: 2,
        display: 'flex',
        flexDirection: 'row-reverse',
      }}>
        <Legend />
      </Box>
      <Box sx={{
        position: 'absolute',
        top: '64px', // size of the menu bar
        left: 0,
        zIndex: 1,
        width: '100%',
        height: 'calc(100% - 64px)'
      }}>
        <InteractiveMap
          {...countryConfig}
          provinces={provinceAreas}
          cyclonePoints={cyclone?.cyclone_data}
        />
      </Box>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Page>

  );
}

export default Cyclone;
import { useContext } from 'react';

import CountryContext from '../contexts/CountryContext';
import * as countriesConfigurations from '../configurations/countriesConfigurations.json';

function useCountry() {
  const {
    country,
    currency,
    setCountry,
    setCurrency
  } = useContext(CountryContext);

  const onChangeCountry: (country: string) => void = (country) => {
    localStorage.setItem('country', country);
    setCountry(country);
    const configuredCountry = countriesConfigurations.countries.find(c => c.code === country);
    setCurrency(configuredCountry ? configuredCountry.currency : '$');
  };

  return {
    country,
    currency,
    onChangeCountry
  };
}

export default useCountry;
import { useCallback, useEffect, useState } from "react";
import Page from "../components/Page";
import DroughtChart from "../components/DroughtChart";
import Province from "../models/Province";
import ProvinceService from '../services/Province';
import PrecipitationService from '../services/Precipitation';
import UtilsService from '../services/Utils';
import moment from "moment";
import {
  Paper,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Button,
  TextField,
  Box,
  InputAdornment,
  debounce,
} from "@mui/material";
import Sample from "../components/Sample";
import CustomTable from "../components/CustomTable";
import DatePicker from "@mui/lab/DatePicker";
import { useTheme } from "@mui/system";
import useCountry from "../hooks/country";

const headersLegend = [
  { index: 'trigger', title: 'Trigger' },
  { index: 'trigger_amount', title: 'Precipitation' },
  { index: 'payout', title: 'Payout' }
];

interface ContentLegend {
  trigger: string,
  trigger_amount: string,
  payout: string,
};

const _legend: ContentLegend[] = [
  { trigger: 'yellow_trigger', trigger_amount: '-', payout: '30%' },
  { trigger: 'orange_trigger', trigger_amount: '-', payout: '70%' },
  { trigger: 'red_trigger', trigger_amount: '-', payout: '100%' }
]

const headersTriggers = [
  { index: 'date', title: 'Date', transformer: (d: string) => UtilsService.formatDate(d) },
  { index: 'value', title: 'Precipitation', transformer: (v: number) => UtilsService.formatPrecipitation(v) },
  { index: 'trigger', title: 'Trigger' },
  { index: 'payout', title: 'Payout' }
];

interface ContentTriggers {
  date: string,
  value: number,
  trigger: string,
  payout: string
};

export default function Drought() {
  const theme = useTheme();

  const [provinces, setProvinces] = useState<Province[]>([]);
  const [selectedProvince, setSelectedProvince] = useState<Province | undefined>(undefined);
  const [selectedYear, setSelectedYear] = useState<moment.Moment | null>(moment('01/01/2020'));
  const [selectedDateErrorMsg, setSelectedDateErrorMsg] = useState<string>('');
  const [refresh, setRefresh] = useState<boolean>(false);

  const [chartPrecipitation, setChartPrecipitation] = useState<{ month: number, year: number, value: number }[] | undefined>(undefined);

  const [computedTriggers, setComputedTriggers] = useState<ContentTriggers[]>([]);
  const [triggersToShow, setTriggersToShow] = useState<ContentTriggers[]>([]);
  const [triggersPageNb, setTriggersPageNb] = useState<number>(0);
  const [triggersPageSize, setTriggersPageSize] = useState<number>(5);

  const [sumInsured, setSumInsured] = useState<number>(1000000);

  const [legend, setLegend] = useState<ContentLegend[]>(_legend);

  const { country, currency } = useCountry();

  const loadChart = useCallback((selectedProvince) => {
    if (selectedProvince && selectedYear) {
      setLegend(l => [
        { ...l[0], trigger_amount: UtilsService.formatPrecipitation(parseFloat(selectedProvince.yellow_trigger.drought)) },
        { ...l[1], trigger_amount: UtilsService.formatPrecipitation(parseFloat(selectedProvince.orange_trigger.drought)) },
        { ...l[2], trigger_amount: UtilsService.formatPrecipitation(parseFloat(selectedProvince.red_trigger.drought)) }
      ]);
      PrecipitationService.getByProvinceBetween(
        selectedProvince.id.toString(),
        selectedYear.startOf('year').format('YYYY-MM-DD'),
        selectedYear.endOf('year').format('YYYY-MM-DD')
      ).then(data => {
        const res: { month: number, year: number, value: number }[] = [];
        data.forEach(precipitation => {
          const monthPrecipitation = res.find(p => p.month === moment(precipitation.date).get('month') && p.year === moment(precipitation.date).get('year'));
          if (monthPrecipitation) {
            monthPrecipitation.value = monthPrecipitation.value + parseFloat(precipitation.value);
          } else {
            res.push({ month: moment(precipitation.date).get('month'), year: moment(precipitation.date).get('year'), value: parseFloat(precipitation.value) });
          }
        });
        setChartPrecipitation(res);
        if (res !== undefined) {
          const triggers: ContentTriggers[] = res
            .filter(pre => Number(pre.value) <= Number(selectedProvince.yellow_trigger.drought))
            .map(pre => ({
              date: moment().month(pre.month).year(pre.year).endOf('month').toString(),
              value: pre.value,
              trigger: pre.value <= Number(selectedProvince.red_trigger.drought)
                ? 'red_trigger' : pre.value <= Number(selectedProvince.orange_trigger.drought)
                  ? 'orange_trigger' : 'yellow_trigger',
              payout: `${UtilsService.formatCurrency(sumInsured * (pre.value <= Number(selectedProvince.red_trigger.drought)
                ? 1 : pre.value <= Number(selectedProvince.orange_trigger.drought)
                  ? 0.7 : 0.3), currency)}`
            }));
          setTriggersPageNb(0);
          setComputedTriggers(triggers);
        }
      });
    }
  }, [selectedYear, sumInsured, currency]);

  useEffect(() => {
    ProvinceService.getAllProvincesOfCountry(country)
      .then(provs => {
        setProvinces(provs);
        setSelectedProvince(provs[0]);
        loadChart(provs[0]);
      })
      .catch(err => {
        console.log(err);
      });
  }, [country]);

  useEffect(() => {
    loadChart(selectedProvince);
  }, [refresh]);

  useEffect(() => {
    setTriggersToShow(computedTriggers.filter((t, index) => index >= triggersPageNb * triggersPageSize && index < (triggersPageNb + 1) * triggersPageSize));
  }, [triggersPageNb, triggersPageSize, computedTriggers]);

  const handleSumInsuredChange = useCallback(
    debounce((value: number) => {
      setSumInsured(value);
      if (chartPrecipitation !== undefined && selectedProvince !== undefined) {
        const triggers: ContentTriggers[] = chartPrecipitation
          .filter(pre => Number(pre.value) <= Number(selectedProvince.yellow_trigger.drought))
          .map(pre => ({
            date: moment().month(pre.month).year(pre.year).endOf('month').toString(),
            value: pre.value,
            trigger: pre.value <= Number(selectedProvince.red_trigger.drought)
              ? 'red_trigger' : pre.value <= Number(selectedProvince.orange_trigger.drought)
                ? 'orange_trigger' : 'yellow_trigger',
            payout: `${UtilsService.formatCurrency(value * (pre.value <= Number(selectedProvince.red_trigger.drought)
              ? 1 : pre.value <= Number(selectedProvince.orange_trigger.drought)
                ? 0.7 : 0.3), currency)}`
          }));
        setTriggersPageNb(0);
        setComputedTriggers(triggers);
      }
    }, 200),
    [selectedProvince, chartPrecipitation, currency]
  );

  const handleChangePageProv = useCallback((pageSize: number, pageNb: number) => {
    setTriggersPageNb(pageNb);
    setTriggersPageSize(pageSize);
  }, []);

  const handleChangeRowsPerPageProv = useCallback((pageSize: number) => {
    setTriggersPageNb(0);
    setTriggersPageSize(pageSize);
  }, []);

  return (
    <Page title="Historical Drought">
      <Box sx={{ display: 'flex', width: '100%' }}>
        <Paper sx={{ padding: theme.spacing(2), marginRight: theme.spacing(4), width: '70%' }}>
          <Box sx={{
            padding: theme.spacing(2, 0),
            display: 'flex',
            flexDirection: 'column',
            '& > .MuiBox-root:not(:first-of-type)': {
              paddingTop: theme.spacing(2)
            }
          }}>
            <Box sx={{
              display: 'flex',
              '& > .MuiBox-root:not(:first-of-type)': {
                paddingLeft: theme.spacing(2)
              }
            }}>
              <Box>
                <FormControl>
                  <InputLabel id="select-label">Province</InputLabel>
                  <Select
                    labelId="select-label"
                    label="Province"
                    value={selectedProvince ? selectedProvince.id : ''}
                    autoWidth
                    onChange={(evt: any) => setSelectedProvince(provinces.filter(prov => prov.id === evt.target.value)[0])}
                  >
                    {provinces.map(prov => (
                      <MenuItem value={prov.id} key={prov.id}>{prov.name}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
              <Box>
                <DatePicker
                  label='Date to' views={['year']}
                  minDate={moment('01/01/2017')} maxDate={moment('12/31/2020')}
                  value={selectedYear} onChange={setSelectedYear}
                  renderInput={(params) => <TextField {...params} error={selectedDateErrorMsg !== ''} helperText={selectedDateErrorMsg} />}
                />
              </Box>
              <Box>
                <TextField
                  label='Sum Insured'
                  type='Number'
                  defaultValue={sumInsured}
                  onChange={(e) => handleSumInsuredChange(parseFloat(e.target.value))}
                  InputProps={{
                    endAdornment: <InputAdornment position="start">{currency}</InputAdornment>
                  }}
                />
              </Box>
            </Box>
            <Box>
              <Button
                variant='contained'
                color='primary'
                onClick={() => setRefresh(!refresh)}
              >Apply filter</Button>
            </Box>
          </Box>
          <Box sx={{ height: '60vh', position: 'relative' }}>
            <Sample />
            <DroughtChart
              data={chartPrecipitation || []}
              red_trigger={selectedProvince ? parseFloat(selectedProvince.red_trigger.drought) : 0}
              orange_trigger={selectedProvince ? parseFloat(selectedProvince.orange_trigger.drought) : 0}
              yellow_trigger={selectedProvince ? parseFloat(selectedProvince.yellow_trigger.drought) : 0}
            />
          </Box>
        </Paper>
        <Box sx={{ width: '30%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
          <Box sx={{ marginBottom: theme.spacing(4) }}>
            <CustomTable
              title="Legend"
              headers={headersLegend}
              content={legend}
              disablePagination
              disableSearch
              watermark
            />
          </Box>
          <Box>
            <CustomTable
              title="Payouts"
              headers={headersTriggers}
              content={triggersToShow}
              total={computedTriggers.length}
              pageNb={triggersPageNb}
              pageSize={triggersPageSize}
              pageSizeOptions={[5, 10, 15]}
              onChangePage={handleChangePageProv}
              onChangeRowsPerPage={handleChangeRowsPerPageProv}
              disableSearch
              watermark
            />
          </Box>
        </Box>
      </Box>
    </Page>
  );
}
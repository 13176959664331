import ApiService from './Api';
import PrecipitationModel from '../models/Precipitation';

class Precipitation {
  getByProvinceBetween(id: string, start: string, end: string): Promise<PrecipitationModel[]> {
    return ApiService.get(`/precipitations/${id}/between?start=${start}&end=${end}`);
  }

  getMock(id: string, country: string, type: string): Promise<{ precipitations: PrecipitationModel[], triggers: { red: number, orange: number, yellow: number } }> {
    return ApiService.get(`/precipitations/mock/${type}/${country}/${id}`);
  }

  getMockColor(country: string, type: string): Promise<{ provinceId: string, triggerColor: string }[]> {
    return ApiService.get(`/precipitations/color/mock/${type}/${country}`);
  }
}

export default new Precipitation();

import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#39A935',
      dark: '#0C5B2A',
      light: '#22934A'
    },
    secondary: {
      main: '#39759E'
    },
    background: {
      default: '#F6F6F8',
      paper: '#FFFFFF'
    }
  },
  typography: {
    fontFamily: '\'Poppins\', sans-serif;',
  },
});

export default theme;

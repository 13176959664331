import { useState, createContext, ReactElement } from 'react';

interface Context {
  isAuthenticated: boolean;
  setUserId: (userId: string) => void;
}

const AuthContext = createContext({} as Context);

interface AuthContextProviderProps {
  initialUser: string | null;
  children: ReactElement | ReactElement[];
}

function AuthContextProvider({ initialUser, children }: AuthContextProviderProps) {
  const [userId, setUserId] = useState<string | null>(initialUser);
  
  const context: Context = {
    isAuthenticated: userId !== null,
    // isAuthenticated: true,

    setUserId
  };

  return (
    <AuthContext.Provider value={context}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
export { AuthContextProvider };

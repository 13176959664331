import {
  BrowserRouter,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';

import Layout from './Layout';
import LoginPage from '../pages/Login';
import Rainfall from '../pages/Rainfall';
import Drought from '../pages/Drought';
import RainfallMap from '../pages/RainfallMap';
import DroughtMap from '../pages/DroughtMap';
import useAuth from '../hooks/auth';
import Cyclone from '../pages/Cyclone';
import Dashboard from '../pages/Dashboard';

const Child = ({ isAuthenticated }: { isAuthenticated: boolean }) => {

  return (
    <>
      {!isAuthenticated && (
        <Switch>
          <Route path="/login/:result?">
            <LoginPage />
          </Route>
          <Redirect
            to='/login'
          />
        </Switch>
      )}
      {isAuthenticated && (
        <Layout>
          <Switch>
            <Route path="/payouts/payout-calculator/historical-rainfall-analysis">
              <Rainfall />
            </Route>
            <Route path="/payouts/payout-calculator/historical-drought-analysis">
              <Drought />
            </Route>
            <Route path="/sales/my-policies/rainfall-protection">
              <RainfallMap />
            </Route>
            <Route path="/sales/my-policies/cyclone-protection">
              <Cyclone />
            </Route>
            <Route path="/sales/my-policies/drought-protection">
              <DroughtMap />
            </Route>
            <Route path="/sales/dashboard">
              <Dashboard />
            </Route>
            <Redirect
              to='/payouts/payout-calculator/historical-rainfall-analysis'
            />
          </Switch>
        </Layout>
      )}
    </>
  );
};

const Router = () => {
  const {
    isAuthenticated
  } = useAuth();

  return (
    <BrowserRouter>
      <Child {...{ isAuthenticated }} />
    </BrowserRouter>
  );
}

export default Router;

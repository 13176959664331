import {
  makeStyles,
  createStyles,
  Box,
  Typography
} from '@material-ui/core';
import TriggerDot from './TriggerDot';
import Precipitation from "../models/Precipitation";
import PrecipitationService from '../services/Precipitation';
import PrecipitationChart from "../components/PrecipitationChart";
import { useEffect, useState } from 'react';
import moment from 'moment';
import useCountry from '../hooks/country';

const useStyles = makeStyles((theme) =>
  createStyles({
    flex: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-end',
      width: '100%',
      height: '300px'
    },
  }),
);

interface Props { provinceId: string }

function RainfallMapPopup({ provinceId }: Props) {
  const classes = useStyles();
  const [precipitations, setPrecipitations] = useState<Precipitation[]>([]);
  const [triggers, setTriggers] = useState<{ red: number, orange: number, yellow: number }>();
  const { country } = useCountry();

  useEffect(() => {
    PrecipitationService.getMock(provinceId, country, 'rainfall').then(res => {
      setPrecipitations(res.precipitations);
      setTriggers(res.triggers);
    },
      err => { console.log(err) }
    );
  }, [provinceId]);

  return (
    <Box className={classes.flex}>
      <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-evenly', width: '30%' }}>
          <TriggerDot level={0} />
          <Typography>-</Typography>
          <Typography>10%</Typography>
          <Typography>-</Typography>
          <Typography>{triggers ? triggers.yellow : '-'} mm</Typography>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-evenly', width: '30%' }}>
          <TriggerDot level={1} />
          <Typography>-</Typography>
          <Typography>15%</Typography>
          <Typography>-</Typography>
          <Typography>{triggers ? triggers.orange : '-'} mm</Typography>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-evenly', width: '30%' }}>
          <TriggerDot level={2} />
          <Typography>-</Typography>
          <Typography>20%</Typography>
          <Typography>-</Typography>
          <Typography>{triggers ? triggers.red : '-'} mm</Typography>
        </Box>
      </Box>
      <PrecipitationChart
        precipitations={precipitations}
        red_trigger={triggers ? triggers.red : 150}
        orange_trigger={triggers ? triggers.orange : 100}
        yellow_trigger={triggers ? triggers.yellow : 50}
        min_date={(precipitations.length > 0 ? moment(precipitations[0].date) : moment()).startOf('day').valueOf()}
        max_date={(precipitations.length > 0 ? moment(precipitations[precipitations.length - 1].date) : moment()).startOf('day').valueOf()}
      />
    </Box>
  );
}

export default RainfallMapPopup;
import {
  Box,
  Backdrop,
  CircularProgress
} from '@mui/material';

import Page from '../components/Page';
import { ReactElement, useCallback, useState } from 'react';

import { useEffect } from 'react';
import { Color } from '@material-ui/core';
import GADMS from '../services/GADMS';
import PrecipitationService from '../services/Precipitation';
import InteractiveMap from '../components/InteractiveMap/InteractiveMap';
import { green, orange, red, yellow } from '@mui/material/colors';
import useCountry from '../hooks/country';
import * as countriesConfigurations from '../configurations/countriesConfigurations.json';
import DroughtMapPopup from '../components/DroughtMapPopup';

const wkt = require('wkt');

interface DataProvinces {
  id: number;
  name: string;
  multiPolygon: { type: string, coordinates: any[] };
  color: Color | string;
  popup: ReactElement
}

function DroughtMap() {
  const [isLoading, setIsLoading] = useState(false)
  const [provinceAreas, setProvinceAreas] = useState<{ geometry: string, name: string, provinceId: string, triggerColor: string }[]>([]);

  const { country } = useCountry();
  const [countryConfig, setCountryConfig] = useState(countriesConfigurations.countries[0]);

  useEffect(() => {
    setIsLoading(true);
    setCountryConfig(countriesConfigurations.countries.find(c => c.code === country) || countriesConfigurations.countries[0]);
    GADMS.getGeometriesByCountry(country, 'drought').then(async (data) => {
      const colors = await PrecipitationService.getMockColor(country, 'drought');
      setProvinceAreas(data.map(d => {
        const color = colors.find(c => c.provinceId === d.provinceId);
        return { ...d, triggerColor: color !== undefined ? color.triggerColor : 'green' }
      }));
      setIsLoading(false);
    });
  }, [country]);

  const convertToMultiPolygon = useCallback((geometry: any) => {
    const geo = wkt.parse(geometry);
    if (geo.type === "Polygon") {
      return {
        type: "MultiPolygon",
        coordinates: [geo.coordinates]
      };
    }
    else {
      return geo;
    }
  }, []);

  const prepare = useCallback((dataProvinces: { geometry: string, name: string, provinceId: string, triggerColor: string }[]): DataProvinces[] => {
    const toReturn: DataProvinces[] = [];
    dataProvinces.forEach((d, index: number) => {
      toReturn.push({
        id: index,
        name: d.name,
        multiPolygon: convertToMultiPolygon(d.geometry),
        color: d.triggerColor === 'red' ? red[500] : d.triggerColor === 'orange' ? orange[500] : d.triggerColor === 'yellow' ? yellow[500] : green[500],
        popup: <DroughtMapPopup provinceId={d.provinceId} />
      });
    });
    return toReturn;
  }, [convertToMultiPolygon]);

  return (
    <Page title="Drought protection" subtitle={`from ${countryConfig.map.drought.date_range.start} to ${countryConfig.map.drought.date_range.end}`}>
      <Box sx={{
        position: 'absolute',
        top: '64px', // size of the menu bar
        left: 0,
        zIndex: 1,
        width: '100%',
        height: 'calc(100% - 64px)'
      }}>
        <InteractiveMap
          {...countryConfig}
          provinces={prepare(provinceAreas)}
        />
      </Box>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Page>

  );
}

export default DroughtMap;
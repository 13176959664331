import {
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Popover,
} from '@mui/material';
import { useTheme } from "@mui/system";
import {
  NavLink as RouterLink,
} from 'react-router-dom';

import React, { useEffect, useState } from 'react';
import SubMenuItem from './SubMenuItem';

interface Props {
  id: string,
  name: string,
  icon?: any,
  link: string,
  disable?: boolean
  menu: {
    name: string,
    link: string,
    disable?: boolean
    submenu?: {
      name: string,
      link?: string,
      disable?: boolean,
      isActive?: boolean,
      callback?: Function
    }[]
  }[]
}

function DropdownListItem({ id, name, icon: Icon, link, menu }: Props) {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [mouseOverButton, setMouseOverButton] = useState(false);
  const [mouseOverMenu, setMouseOverMenu] = useState(false);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(mouseOverButton || mouseOverMenu);
  }, [mouseOverButton, mouseOverMenu]);

  const handleClose = () => {
    setMouseOverButton(false);
    setMouseOverMenu(false);
  };

  const enterButton = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setMouseOverButton(true);
  };

  const leaveButton = () => {
    setMouseOverButton(false);
  };

  const enterMenu = () => {
    setMouseOverMenu(true);
  };

  const leaveMenu = () => {
    setMouseOverMenu(false);
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
  };

  return (
    <>
      <ListItemButton
        id={`${id}`}
        component={RouterLink}
        to={link}
        exact={false}
        sx={{
          '& span': {
            fontWeight: 'bold',
          },
          '& svg': {
            fill: theme.palette.primary.main,
          },
          '&:hover': {
            borderBottomColor: theme.palette.primary.main,
            backgroundColor: theme.palette.background.paper
          },
          borderBottom: `3px ${theme.palette.background.paper} solid`,
          transition: 'border 0.2s'
        }}
        activeStyle={{
          color: theme.palette.primary.main,
          borderBottomColor: theme.palette.primary.main,
        }}
        onClick={handleClick}
        onMouseEnter={enterButton}
        onMouseLeave={leaveButton}
      >
        {Icon && (<ListItemIcon><Icon /></ListItemIcon>)}
        <ListItemText primary={name} />
      </ListItemButton>
      <Popover
        sx={{
          pointerEvents: 'none',
          '& .MuiPaper-root': {
            pointerEvents: 'auto',
          }
        }}
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
        open={open}
        onClose={handleClose}
        onMouseEnter={enterMenu}
        onMouseLeave={leaveMenu}
        disableRestoreFocus
      >
        {menu.map((m, index) => {
          if (m.submenu && m.submenu.length > 0) {
            return (
              <SubMenuItem
                key={index}
                name={m.name}
                link={m.link}
                handleClose={handleClose}
                submenu={m.submenu}
              />
            )
          } else {
            return (
              <ListItemButton
                key={index}
                component={RouterLink}
                to={m.link}
                exact={false}
                onClick={handleClose}
                sx={{
                  borderLeft: `3px ${theme.palette.background.paper} solid`,
                  '&:hover': {
                    color: theme.palette.primary.main,
                    borderLeftColor: theme.palette.primary.main,
                  }
                }}
                disabled={m.disable}
              >
              {Icon && (<ListItemIcon><Icon /></ListItemIcon>)}
              <ListItemText primary={m.name} />
              </ListItemButton>
            )
          }
        })}
      </Popover>
    </>
  );
};

export default DropdownListItem;

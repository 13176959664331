import {
  ListItemButton,
  ListItemText,
  Popover,
} from '@mui/material';
import { useTheme } from "@mui/system";
import {
  NavLink as RouterLink,
} from 'react-router-dom';

import React, { useEffect, useState } from 'react';
import { alpha } from '@material-ui/core';

interface Props {
  name: string,
  link: string,
  disable?: boolean,
  handleClose: Function,
  submenu: {
    name: string,
    link?: string,
    disable?: boolean,
    isActive?: boolean,
    callback?: Function
  }[]
}

function SubMenuItem({ name, link, submenu, handleClose: handleParentClose }: Props) {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [mouseOverButton, setMouseOverButton] = useState(false);
  const [mouseOverMenu, setMouseOverMenu] = useState(false);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(mouseOverButton || mouseOverMenu);
  }, [mouseOverButton, mouseOverMenu]);

  const handleClose = () => {
    setMouseOverButton(false);
    setMouseOverMenu(false);
    handleParentClose();
  };

  const enterButton = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setMouseOverButton(true);
  };

  const leaveButton = () => {
    setMouseOverButton(false);
  };

  const enterMenu = () => {
    setMouseOverMenu(true);
  };

  const leaveMenu = () => {
    setMouseOverMenu(false);
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
  };

  return (
    <>
      <ListItemButton
        component={RouterLink}
        to={link}
        exact={false}
        onClick={handleClick}
        onMouseEnter={enterButton}
        onMouseLeave={leaveButton}
        selected={mouseOverButton || mouseOverMenu}
        sx={{
          borderLeft: `3px ${theme.palette.background.paper} solid`,
          '&:hover, &.Mui-selected': {
            color: theme.palette.primary.main,
            borderLeftColor: theme.palette.primary.main
          }
        }}
      >
        <ListItemText primary={name} />
      </ListItemButton>
      <Popover
        sx={{
          pointerEvents: 'none',
          '& .MuiPaper-root': {
            pointerEvents: 'auto',
          }
        }}
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "left" }}
        open={open}
        onClose={handleClose}
        onMouseEnter={enterMenu}
        onMouseLeave={leaveMenu}
        disableRestoreFocus
      >
        {submenu.map((m, index) => {
          if (m.link) {
            return (
              <ListItemButton
                key={index}
                component={RouterLink}
                to={m.link}
                exact={false}
                onClick={() => { handleClose(); if (m.callback) m.callback(); }}
                sx={{
                  borderLeft: `3px ${theme.palette.background.paper} solid`,
                  borderLeftColor: m.isActive ? theme.palette.secondary.main : theme.palette.background.paper,
                  color: m.isActive ? theme.palette.secondary.main : 'default',
                  backgroundColor: m.isActive ? alpha(theme.palette.secondary.main, 0.05) : 'default',
                  '&:hover': {
                    color: theme.palette.primary.main,
                    borderLeftColor: theme.palette.primary.main,
                  }
                }}
                disabled={m.disable}
              >
                <ListItemText primary={m.name} />
              </ListItemButton>
            )
          } else {
            return (
              <ListItemButton
                key={index}
                onClick={() => { handleClose(); if (m.callback) m.callback(); }}
                sx={{
                  borderLeft: `3px ${theme.palette.background.paper} solid`,
                  borderLeftColor: m.isActive ? theme.palette.secondary.main : theme.palette.background.paper,
                  color: m.isActive ? theme.palette.secondary.main : 'default',
                  backgroundColor: m.isActive ? alpha(theme.palette.secondary.main, 0.05) : 'default',
                  '&:hover': {
                    color: theme.palette.primary.main,
                    borderLeftColor: theme.palette.primary.main,
                  }
                }}
                disabled={m.disable}
              >
                <ListItemText primary={m.name} />
              </ListItemButton>
            )
          }
        })}
      </Popover>
    </>
  );
};

export default SubMenuItem;

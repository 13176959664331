export default function Sample() {
  return (
    <svg pointerEvents="none" viewBox="0 0 100 100" preserveAspectRatio="none" style={{ position: 'absolute', width: '100%', height: '100%' }}>
      <text
        textLength='120'
        lengthAdjust="spacingAndGlyphs"
        textAnchor="middle"
        transform="translate(54,54) rotate(-45)"
        fontFamily="'Poppins',sans-serif"
        fill="#00000020"
      >
        SAMPLE
      </text>
    </svg>
  );
}
import { useCallback, useEffect, useState } from "react";
import Page from "../components/Page";
import PrecipitationChart from "../components/PrecipitationChart";
import Precipitation from "../models/Precipitation";
import Province from "../models/Province";
import ProvinceService from '../services/Province';
import PrecipitationService from '../services/Precipitation';
import UtilsService from '../services/Utils';
import moment from "moment";
import {
  Paper,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Button,
  TextField,
  Box,
  InputAdornment,
  debounce,
} from "@mui/material";
import CustomTable from "../components/CustomTable";
import DatePicker from "@mui/lab/DatePicker";
import { useTheme } from "@mui/system";
import useCountry from "../hooks/country";
import Sample from "../components/Sample";

const headersLegend = [
  { index: 'trigger', title: 'Trigger' },
  { index: 'trigger_amount', title: 'Precipitation' },
  { index: 'payout', title: 'Payout' }
];

interface ContentLegend {
  trigger: string,
  trigger_amount: string,
  payout: string,
};

const _legend: ContentLegend[] = [
  { trigger: 'yellow_trigger', trigger_amount: '-', payout: '30%' },
  { trigger: 'orange_trigger', trigger_amount: '-', payout: '70%' },
  { trigger: 'red_trigger', trigger_amount: '-', payout: '100%' }
]

const headersTriggers = [
  { index: 'date', title: 'Date', transformer: (d: string) => UtilsService.formatDate(d) },
  { index: 'value', title: 'Precipitation', transformer: (v: number) => UtilsService.formatPrecipitation(v) },
  { index: 'trigger', title: 'Trigger' },
  { index: 'payout', title: 'Payout' }
];

interface ContentTriggers {
  id: number,
  date: string,
  value: number,
  trigger: string,
  payout: string
};

export default function Rainfall() {
  const theme = useTheme();

  const [provinces, setProvinces] = useState<Province[]>([]);
  const [selectedProvince, setSelectedProvince] = useState<Province | undefined>(undefined);
  const [selectedYear, setSelectedYear] = useState<moment.Moment | null>(moment('01/01/2020'));
  const [selectedDateErrorMsg, setSelectedDateErrorMsg] = useState<string>('');
  const [refresh, setRefresh] = useState<boolean>(false);

  const [chartPrecipitation, setChartPrecipitation] = useState<Precipitation[] | undefined>(undefined);
  const [chartProvinceTriggers, setChartProvinceTriggers] = useState<{ red_trigger: number, orange_trigger: number, yellow_trigger: number } | undefined>(undefined);

  const [computedTriggers, setComputedTriggers] = useState<ContentTriggers[]>([]);
  const [triggersToShow, setTriggersToShow] = useState<ContentTriggers[]>([]);
  const [triggersPageNb, setTriggersPageNb] = useState<number>(0);
  const [triggersPageSize, setTriggersPageSize] = useState<number>(5);

  const [sumInsured, setSumInsured] = useState<number>(1000000);

  const [legend, setLegend] = useState<ContentLegend[]>(_legend);

  const { country, currency } = useCountry();

  const loadChart = useCallback((selectedProvince) => {
    if (selectedProvince && selectedYear) {
      setSelectedDateErrorMsg('');
      setChartProvinceTriggers({
        red_trigger: parseFloat(selectedProvince.red_trigger.rainfall),
        orange_trigger: parseFloat(selectedProvince.orange_trigger.rainfall),
        yellow_trigger: parseFloat(selectedProvince.yellow_trigger.rainfall),
      });
      setLegend(l => [
        { ...l[0], trigger_amount: UtilsService.formatPrecipitation(selectedProvince.yellow_trigger.rainfall) },
        { ...l[1], trigger_amount: UtilsService.formatPrecipitation(selectedProvince.orange_trigger.rainfall) },
        { ...l[2], trigger_amount: UtilsService.formatPrecipitation(selectedProvince.red_trigger.rainfall) }
      ]);
      PrecipitationService.getByProvinceBetween(
        selectedProvince.id.toString(),
        selectedYear.startOf('year').format('YYYY-MM-DD'),
        selectedYear.endOf('year').format('YYYY-MM-DD')
      )
        .then(pres => {
          setChartPrecipitation(pres);
          if (pres !== undefined) {
            const triggers: ContentTriggers[] = pres
              .filter(pre => Number(pre.value) >= Number(selectedProvince.yellow_trigger.rainfall))
              .map(pre => ({
                id: pre.id,
                date: pre.date,
                value: parseFloat(pre.value),
                trigger: Number(pre.value) >= Number(selectedProvince.red_trigger.rainfall)
                  ? 'red_trigger' : Number(pre.value) >= Number(selectedProvince.orange_trigger.rainfall)
                    ? 'orange_trigger' : 'yellow_trigger',
                payout: `${UtilsService.formatCurrency(sumInsured * (Number(pre.value) >= Number(selectedProvince.red_trigger.rainfall)
                  ? 1 : Number(pre.value) >= Number(selectedProvince.orange_trigger.rainfall)
                    ? 0.7 : 0.3), currency)}`
              }));
            setTriggersPageNb(0);
            setComputedTriggers(triggers);
          }
        })
        .catch(err => {
          console.log(err);
        });
    }
  }, [selectedYear, sumInsured, currency])

  useEffect(() => {
    ProvinceService.getAllProvincesOfCountry(country)
      .then(provs => {
        setProvinces(provs);
        setSelectedProvince(provs[0]);
        loadChart(provs[0]);
      })
      .catch(err => {
        console.log(err);
      })
  }, [country]);

  useEffect(() => {
    loadChart(selectedProvince);
  }, [refresh]);

  useEffect(() => {
    setTriggersToShow(computedTriggers.filter((t, index) => index >= triggersPageNb * triggersPageSize && index < (triggersPageNb + 1) * triggersPageSize));
  }, [triggersPageNb, triggersPageSize, computedTriggers]);

  const handleSumInsuredChange = useCallback(
    debounce((value: number) => {
      setSumInsured(value);
      if (chartPrecipitation !== undefined && selectedProvince !== undefined) {
        const triggers: ContentTriggers[] = chartPrecipitation
          .filter(pre => Number(pre.value) >= Number(selectedProvince.yellow_trigger.rainfall))
          .map(pre => ({
            id: pre.id,
            date: pre.date,
            value: parseFloat(pre.value),
            trigger: Number(pre.value) >= Number(selectedProvince.red_trigger.rainfall)
              ? 'red_trigger' : Number(pre.value) >= Number(selectedProvince.orange_trigger.rainfall)
                ? 'orange_trigger' : 'yellow_trigger',
            payout: `${UtilsService.formatCurrency(value * (Number(pre.value) >= Number(selectedProvince.red_trigger.rainfall)
              ? 1 : Number(pre.value) >= Number(selectedProvince.orange_trigger.rainfall)
                ? 0.7 : 0.3), currency)}`
          }));
        setTriggersPageNb(0);
        setComputedTriggers(triggers);
      }
    }, 200),
    [selectedProvince, chartPrecipitation, currency]
  );

  const handleChangePageProv = useCallback((pageSize: number, pageNb: number) => {
    setTriggersPageNb(pageNb);
    setTriggersPageSize(pageSize);
  }, []);

  const handleChangeRowsPerPageProv = useCallback((pageSize: number) => {
    setTriggersPageNb(0);
    setTriggersPageSize(pageSize);
  }, []);

  return (
    <Page title="Historical Rainfall">
      <Box sx={{ display: 'flex', width: '100%' }}>
        <Paper sx={{ padding: theme.spacing(2), marginRight: theme.spacing(4), width: '70%' }}>
          <Box sx={{
            padding: theme.spacing(2, 0),
            display: 'flex',
            flexDirection: 'column',
            '& > .MuiBox-root:not(:first-of-type)': {
              paddingTop: theme.spacing(2)
            }
          }}>
            <Box sx={{
              display: 'flex',
              '& > .MuiBox-root:not(:first-of-type)': {
                paddingLeft: theme.spacing(2)
              }
            }}>
              <Box>
                <FormControl>
                  <InputLabel id="select-label">Province</InputLabel>
                  <Select
                    labelId="select-label"
                    label="Province"
                    value={selectedProvince ? selectedProvince.id : ''}
                    autoWidth
                    onChange={(evt: any) => setSelectedProvince(provinces.filter(prov => prov.id === evt.target.value)[0])}
                  >
                    {provinces.map(prov => (
                      <MenuItem value={prov.id} key={prov.id}>{prov.name}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
              <Box>
                <DatePicker
                  label='Date to' views={['year']}
                  minDate={moment('01/01/2017')} maxDate={moment('12/31/2020')}
                  value={selectedYear} onChange={setSelectedYear}
                  renderInput={(params) => <TextField {...params} error={selectedDateErrorMsg !== ''} helperText={selectedDateErrorMsg} />}
                />
              </Box>
              <Box>
                <TextField
                  label='Sum Insured'
                  type='Number'
                  defaultValue={sumInsured}
                  onChange={(e) => handleSumInsuredChange(parseFloat(e.target.value))}
                  InputProps={{
                    endAdornment: <InputAdornment position="start">{currency}</InputAdornment>
                  }}
                />
              </Box>
            </Box>
            <Box>
              <Button
                variant='contained'
                color='primary'
                onClick={() => setRefresh(!refresh)}
              >Apply filter</Button>
            </Box>
          </Box>
          <Box sx={{ height: '60vh', position: 'relative' }}>
            <Sample />
            {chartProvinceTriggers && chartPrecipitation && (
              <PrecipitationChart
                precipitations={chartPrecipitation}
                red_trigger={chartProvinceTriggers.red_trigger}
                orange_trigger={chartProvinceTriggers.orange_trigger}
                yellow_trigger={chartProvinceTriggers.yellow_trigger}
              />
            )}
          </Box>
        </Paper>
        <Box sx={{ width: '30%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
          <Box sx={{ marginBottom: theme.spacing(4) }}>
            <CustomTable
              title="Legend"
              headers={headersLegend}
              content={legend}
              disablePagination
              disableSearch
              watermark
            />
          </Box>
          <Box>
            <CustomTable
              title="Payouts"
              headers={headersTriggers}
              content={triggersToShow}
              total={computedTriggers.length}
              pageNb={triggersPageNb}
              pageSize={triggersPageSize}
              pageSizeOptions={[5, 10, 15]}
              onChangePage={handleChangePageProv}
              onChangeRowsPerPage={handleChangeRowsPerPageProv}
              disableSearch
              watermark
            />
          </Box>
        </Box>
      </Box>
    </Page>
  );
}
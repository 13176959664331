import { Box, Paper, Typography } from '@mui/material';
import { blue } from '@mui/material/colors';
import { useTheme } from '@mui/system';
import { ChartData, ChartOptions } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import InteractiveMap from '../components/InteractiveMap/InteractiveMap';
import Page from '../components/Page';
import * as mapAreas from '../configurations/dashboardMapData.json';


const chart: { data: ChartData<'bar'>, options: ChartOptions<'bar'> } = ({
  data: {
    datasets: [
      {
        type: 'bar',
        label: 'FPOs',
        data: [450, 515, 425, 550, 650],
        backgroundColor: '#39A935'
      },
    ],
    labels: ['January', 'Feburary', 'March', 'April', 'May']
  },
  options: {
    maintainAspectRatio: false,
    scales: {
      y: {
        min: 0,
        ticks: {
          stepSize: 100
        }
      }
    },
    plugins: {
      legend: { display: false },
      tooltip: {
        displayColors: false,
        callbacks: {
          label: function (context: any) {
            var label = context.dataset.label || '';
            if (label) {
              label += ': ';
            }
            if (context.parsed.y !== null) {
              label += context.parsed.y;
            }
            return label;
          }
        }
      }
    }
  }
});

function Dashboard() {
  const theme = useTheme();

  const provinces = mapAreas.areas.sort((a, b) => a.nbOfCoops - b.nbOfCoops).map((d, index: number) => ({
    id: index,
    name: d.name,
    multiPolygon: d.multiPolygon,
    color: d.nbOfCoops > 600 ? blue[900] : d.nbOfCoops > 300 ? blue[600] : blue[300],
    popup: <Box>{d.nbOfCoops} FPOs</Box>,
    popupMinSize: 0
  })
  );

  return (
    <Page title="Dashboard">
      <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', height: '100%' }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
          <Paper sx={{ padding: theme.spacing(2), width: '20%' }}>
            <Typography variant='h5' align='center'>2,590</Typography>
            <Typography align='center'>Insured FPOs</Typography>
          </Paper>
          <Paper sx={{ padding: theme.spacing(2), width: '20%' }}>
            <Typography variant='h5' align='center'>₹10.25 million</Typography>
            <Typography align='center'>Worth of crops protected</Typography>
          </Paper>
          <Paper sx={{ padding: theme.spacing(2), width: '20%' }}>
            <Typography variant='h5' align='center'>₹1.4 million</Typography>
            <Typography align='center'>Payouts to date</Typography>
          </Paper>
          <Paper sx={{ padding: theme.spacing(2), width: '20%' }}>
            <Typography variant='h5' align='center'>8 Days</Typography>
            <Typography align='center'>Average payout processing time</Typography>
          </Paper>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', height: '100%' }}>
          <Box sx={{ padding: theme.spacing(2, 0), width: '45%' }}>
            <InteractiveMap
              center={[20.4630933, 85.7975348]}
              maxBounds={[[19.0, 84.0], [22.0, 88.0]]}
              maxZoom={9}
              minZoom={8}
              zoom={9}
              provinces={provinces}
              showBorders={true}
            />
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', padding: theme.spacing(2), width: '45%' }}>
            <Typography variant='h5'>New FPOs</Typography>
            <Box width={'100%'} height={'100%'}>
              <Bar
                data={chart.data}
                options={chart.options}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </Page>
  );
}

export default Dashboard;
import {
  Typography,
  Box,
} from '@mui/material';
import { ReactElement } from 'react';
import theme from '../theme';

interface PageProps {
  title: string;
  subtitle?: string;
  children: ReactElement | ReactElement[];
}

function Page({ title, subtitle, children: childrenPage }: PageProps) {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <Typography variant="h4" component="span" sx={{ position: 'relative', zIndex: 10 }}>
        {title}
      </Typography>
      {subtitle && (
        <Typography variant="h6" component="span" sx={{ position: 'relative', zIndex: 10 }}>
          {subtitle}
        </Typography>
      )}
      <Box sx={{ paddingTop: theme.spacing(2), boxSizing: 'border-box', height: '100%' }}>
        {childrenPage}
      </Box>
    </Box>
  );
}

export default Page;

import { Line } from 'react-chartjs-2';

import {
  Box
} from '@mui/material';
import { ChartData, ChartOptions } from 'chart.js';
import 'chartjs-adapter-moment';
import moment from 'moment';
import { useCallback } from 'react';
import { alpha } from '@material-ui/core';

const buildDatasets = (
  data: any[],
  red_trigger: number,
  orange_trigger: number,
  yellow_trigger: number,
  labels: string[]
): { data: ChartData<any>, options: ChartOptions<any> } => ({
  data: {
    datasets: [
      {
        type: 'line',
        label: 'a',
        data: Array(data.length + 3).fill(red_trigger),
        borderColor: '#C92519',
        pointRadius: 0,
      },
      {
        type: 'line',
        label: 'b',
        data: Array(data.length + 3).fill(orange_trigger),
        borderColor: '#FF9900',
        pointRadius: 0,
      },
      {
        type: 'line',
        label: 'c',
        data: Array(data.length + 3).fill(yellow_trigger),
        borderColor: '#FFE600',
        pointRadius: 0,
      },
      {
        type: 'bar',
        label: 'drought data',
        data: [0, ...data, 0],
        backgroundColor: ['#000', ...data.map(d =>
          d <= red_trigger ? alpha('#C92519', 0.8)
            : d <= orange_trigger ? alpha('#FF9900', 0.8)
              : d <= yellow_trigger ? alpha('#FFE600', 0.8)
                : alpha('#39A935', 0.8)
        ), '#000']
      },
    ],
    labels: ['dummy1', ...labels, 'dummy2']
  },
  options: {
    maintainAspectRatio: false,
    scales: {
      x: {
        min: labels[0],
        max: labels[labels.length - 1]
      }
    },
    plugins: {
      legend: { display: false },
      tooltip: {
        displayColors: false,
        callbacks: {
          label: function (context: any) {
            var label = context.dataset.label || '';
            if (label) {
              label += ': ';
            }
            if (context.parsed.y !== null) {
              label += context.parsed.y + " mm";
            }
            return label;
          }
        }
      }
    }
  }
});

interface Props {
  data: {month: number, year: number, value: number}[];
  red_trigger: number;
  orange_trigger: number;
  yellow_trigger: number;
}

function DroughtChart({ data: _data, red_trigger, orange_trigger, yellow_trigger }: Props) {
  const labels = useCallback(() => _data.map(d => moment.months(d.month) + "-" + d.year), [_data]);
  const { data, options } = buildDatasets(_data.map(d => Math.round(d.value)), red_trigger, orange_trigger, yellow_trigger, labels());

  return (
    <Box width={'100%'} height={'100%'}>
      <Line
        data={data}
        options={options}
      />
    </Box>
  )
};

export default DroughtChart;

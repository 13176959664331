import {
  NavLink as RouterLink,
} from 'react-router-dom';
import {
  Box,
  Drawer,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography
} from '@mui/material';
import { useTheme } from '@mui/system';
import DropdownListItem from './DropdownListItem';
import useCountry from '../../hooks/country';
import * as countriesConfigurations from '../../configurations/countriesConfigurations.json';

const drawerHeight = 8 // use theme spacing

function AppBar() {
  const theme = useTheme();
  const {country, onChangeCountry} = useCountry();

  const menus: {
    name: string,
    id: string,
    icon?: any,
    link: string,
    disable?: boolean,
    menu?: {
      name: string,
      link: string,
      disable?: boolean
      submenu?: {
        name: string,
        link?: string,
        disable?: boolean,
        isActive?: boolean,
        callback?: Function
      }[]
    }[]
  }[] = [
      {
        name: 'Sales',
        id: 'linkToHistorical',
        link: '/sales',
        menu: [
          {
            name: 'My policies',
            link: '/sales/my-policies',
            submenu: [
              {
                name: 'Cyclone Protection',
                link: '/sales/my-policies/cyclone-protection'
              },
              {
                name: 'Rainfall Protection',
                link: '/sales/my-policies/rainfall-protection'
              },
              {
                name: 'Drought Protection',
                link: '/sales/my-policies/drought-protection'
              }
            ]
          },
          {
            name: 'Dashboard',
            link: '/sales/dashboard'
          }/* ,
          {
            name: 'Onboarding',
            link: ''
          } */
        ]
      },
      {
        name: 'Payouts',
        id: 'linkToDashboard',
        link: '/payouts',
        menu: [
          {
            name: 'Payouts calculators',
            link: '/payouts/payout-calculator',
            submenu: [
              {
                name: 'Historical Rainfall Analysis',
                link: '/payouts/payout-calculator/historical-rainfall-analysis',
              },
              {
                name: 'Historical Drought Analysis',
                link: '/payouts/payout-calculator/historical-drought-analysis',
              }
            ]
          }/* ,
          {
            name: 'Payouts overviews',
            link: ''
          }, */
        ]
      },
      {
        name: 'More',
        id: 'linkToTriggered',
        link: '/more',
        menu: [
          /* {
            name: 'Data analyst service',
            link: ''
          },
          {
            name: 'Forecasting',
            link: ''
          }, */
          {
            name: 'Countries',
            link: '',
            submenu: countriesConfigurations.countries.map(c => ({
              name: c.name,
              isActive: country === c.code,
              callback: () => onChangeCountry(c.code)
            }))
          },
        ]
      },
    ];

  return (
    <Drawer
      sx={{
        flexShrink: 0,
        position: 'relative',
        height: theme.spacing(drawerHeight),
        width: '100%',
        '& img': {
          height: '50%',
          alignSelf: 'center',
          padding: theme.spacing(0, 4)
        },
        '& .MuiPaper-root': {
          border: 0,
          width: '100%',
          height: theme.spacing(drawerHeight),
          display: 'flex',
          flexDirection: 'row'
        }
      }}
      variant="permanent"
      anchor="left"
    >
      <img src='/logo_ibisa_black.png' alt='CLIMBS logo' />
      <List sx={{
        display: 'flex',
        padding: 0,
        '& .MuiListItemIcon-root': {
          minWidth: theme.spacing(5)
        }
      }}>
        {menus.map(({ name, id, link, icon: Icon, menu, disable }, index) => {
          if (menu) {
            return (
              <DropdownListItem
                id={`${id}`}
                icon={Icon}
                key={index}
                name={name}
                link={link}
                menu={menu}
              />
            )
          } else {
            return (
              <ListItemButton
                id={`${id}`}
                key={index}
                component={RouterLink}
                to={link}
                exact={false}
                sx={{
                  '& span': {
                    fontWeight: 'bold',
                  },
                  '& svg': {
                    fill: theme.palette.primary.main,
                  },
                  '&:hover': {
                    borderBottomColor: theme.palette.primary.main,
                    backgroundColor: theme.palette.background.paper
                  },
                  borderBottom: `3px ${theme.palette.background.paper} solid`,
                  transition: 'border 0.2s'
                }}
                activeStyle={{
                  color: theme.palette.primary.main,
                  borderBottomColor: theme.palette.primary.main,
                }}
                disabled={disable}
              >
                <ListItemIcon><Icon /></ListItemIcon>
                <ListItemText primary={name} />
              </ListItemButton>
            )
          }
        })}
      </List>
      <Box sx={{ marginLeft: 'auto', marginRight: theme.spacing(5), alignSelf: 'center' }}>
        <Typography>
          Sample data and products
        </Typography>
      </Box>
    </Drawer >
  );
};

export default AppBar;

import {
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableFooter,
  TableRow,
  TableCell,
  TablePagination,
  TextField,
  debounce,
  Typography,
  Link,
  Box,
  Collapse,
  IconButton,
} from '@mui/material';
import { useTheme } from '@mui/system';
import { purple } from '@mui/material/colors';
import { styled } from '@mui/material/styles';
import { useCallback, useEffect, useState } from 'react';
import {
  NavLink as RouterLink,
} from 'react-router-dom';

import TriggerDot from '../components/TriggerDot';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import Sample from './Sample';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  border: 'solid 1px #e0e0e0',
  fontWeight: 'bold'
}));

interface CustomTableProps {
  headers: any[];
  content: any[];
  total?: number;
  pageSize?: number;
  pageNb?: number;
  disablePagination?: boolean;
  onChangePage?: Function;
  onChangeRowsPerPage?: Function;
  onChangeSearch?: Function;
  disableSearch?: boolean;
  pageSizeOptions?: number[];
  title?: string;
  disableCollapsable?: boolean;
  watermark?: boolean;
}

function CustomTable({
  headers,
  content,
  total = 0,
  pageSize = 0,
  pageNb = 0,
  disablePagination = false,
  onChangePage = () => { },
  onChangeRowsPerPage = () => { },
  onChangeSearch = () => { },
  disableSearch = false,
  pageSizeOptions = [10, 25, 50, 100],
  title,
  disableCollapsable = false,
  watermark = false
}: CustomTableProps) {
  const theme = useTheme();
  const [search, setSearch] = useState('');
  const [open, setOpen] = useState(disableCollapsable);

  const changeSearch = useCallback(
    (name: string) => debounce(() => {
      onChangeSearch(name);
    }, 200),
    [onChangeSearch]
  );

  useEffect(() => {
    changeSearch(search);
  }, [search, changeSearch])

  const handleChangePage = (event: unknown, newPage: number) => {
    onChangePage(pageSize, newPage);
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChangeRowsPerPage(+event.target.value);
  }

  const handleChangeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  }

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <Paper sx={{ padding: theme.spacing(2) }}>
      {(!disableSearch || title) && (
        <Box sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          paddingBottom: theme.spacing(2)
        }}>
          {title && (
            <Typography variant='h5' style={{ marginRight: 'auto' }}>{title}</Typography>
          )}
          {!disableSearch && open && (
            <TextField
              size="small"
              label="Search"
              variant="outlined"
              color="secondary"
              onChange={handleChangeSearch}
            />
          )}
          {!disableCollapsable && (
            <IconButton onClick={handleClick}>
              {open ? <ExpandLess /> : <ExpandMore />}
            </IconButton>
          )}
        </Box>
      )}
      <Collapse in={open} timeout="auto" unmountOnExit>
        <TableContainer sx={{
          border: `solid 1px ${theme.palette.grey[300]}`,
          borderRadius: theme.spacing(0.5),
          position: 'relative'
        }}>
          {watermark && (
            <Sample />
          )}
          <Table>
            <TableHead>
              <TableRow>
                {headers.map((header) => {
                  if (headers.filter(h => h.subHeaders !== undefined).length > 0) {
                    return (
                      <StyledTableCell
                        key={header.index}
                        align="center"
                        rowSpan={header.subHeaders !== undefined ? 1 : 2}
                        colSpan={header.subHeaders !== undefined ? header.subHeaders.length : 1}
                      >
                        {header.title}
                      </StyledTableCell>
                    )
                  } else {
                    return (<StyledTableCell key={header.index} align="center">{header.title}</StyledTableCell>)
                  }
                })}
              </TableRow>
              {headers.filter(h => h.subHeaders !== undefined).length > 0 && (
                <TableRow>
                  {headers.filter(h => h.subHeaders !== undefined).map((header) => (
                    header.subHeaders.map((sh: { index: string, title: string }) => {
                      return <StyledTableCell key={sh.index} align="center">{sh.title}</StyledTableCell>
                    })))}
                </TableRow>
              )}
            </TableHead>
            <TableBody>
              {content.map((row, index) => (
                <TableRow key={index}>
                  {headers.map((header) => {
                    switch (row[header.index]) {
                      case 'yellow_trigger':
                        return (
                          <TableCell key={header.index} align="center"><TriggerDot level={0} /></TableCell>
                        )
                      case 'orange_trigger':
                        return (
                          <TableCell key={header.index} align="center"><TriggerDot level={1} /></TableCell>
                        )
                      case 'red_trigger':
                        return (
                          <TableCell key={header.index} align="center"><TriggerDot level={2} /></TableCell>
                        )
                      default:
                        if (header.subHeaders !== undefined) {
                          return header.subHeaders.map((sh: { index: string, title: string, transformer: Function }) => {
                            if (sh.transformer) {
                              return (
                                <TableCell key={sh.index} align="center">{sh.transformer(row[header.index][sh.index])}</TableCell>
                              )
                            } else {
                              return (
                                <TableCell key={sh.index} align="center">{row[header.index][sh.index]}</TableCell>
                              )
                            }
                          })
                        } else if (header.isLink) {
                          return (
                            <TableCell key={header.index} align="center">
                              <RouterLink to={row[header.index].link} style={{ textDecoration: 'none' }} id={`tableLink${index}`}>
                                {row[header.index].value}
                              </RouterLink>
                            </TableCell>
                          )
                        } else if (header.isCallback) {
                          return (
                            <TableCell key={header.index} align="center">
                              <Link onClick={() => row[header.index].callback()} sx={{
                                cursor: 'pointer',
                                color: purple[500],
                                '&:hover': {
                                  color: purple[800]
                                }
                              }}>
                                {row[header.index].value}
                              </Link>
                            </TableCell>
                          )
                        } else {
                          if (header.transformer) {
                            return (
                              <TableCell key={header.index} align="center">{header.transformer(row[header.index])}</TableCell>
                            )
                          } else {
                            return (
                              <TableCell key={header.index} align="center">{row[header.index]}</TableCell>
                            )
                          }
                        }
                    }
                  })}
                </TableRow>
              ))}
            </TableBody>
            {!disablePagination && (
              <TableFooter>
                <TableRow>
                  <TablePagination
                    count={total}
                    page={pageNb}
                    onPageChange={handleChangePage}
                    rowsPerPage={pageSize}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    rowsPerPageOptions={pageSizeOptions}
                  />
                </TableRow>
              </TableFooter>
            )}
          </Table>
        </TableContainer>
      </Collapse>
    </Paper>
  );
}

export default CustomTable;
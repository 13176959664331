import {
  ThemeProvider,
} from '@mui/material/styles';
import AdapterDateMoment from '@mui/lab/AdapterMoment';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import Router from './components/Router';
import theme from './theme';
import { AuthContextProvider } from './contexts/AuthContext';
import { CountryContextProvider } from './contexts/CountryContext';

function App() {
  const country = localStorage.getItem('country');

  if (process.env.REACT_APP_ENV === 'production') {
    console.log = function () { };
  }

  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterDateMoment}>
        <div className="app" style={{ height: '100vh' }}>
          <AuthContextProvider initialUser={null}>
            <CountryContextProvider initialCountry={country ? country : 'PHL' }>
              <Router />
            </CountryContextProvider>
          </AuthContextProvider>
        </div>
      </LocalizationProvider>
    </ThemeProvider>
  );
}

export default App;

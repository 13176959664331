import { Color } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import { LatLng } from 'leaflet';
import { ReactElement, useState } from 'react';
import { Polygon, Popup, Tooltip } from 'react-leaflet';

interface Props {
  multiPolygon: { type: string, coordinates: any[] };
  color: Color | string,
  tooltip: string,
  popup?: ReactElement,
  popupMinSize?: number,
  showBorders?: boolean
}

function InteractiveMultiPolygone({ multiPolygon, color, tooltip, popup, popupMinSize, showBorders }: Props) {
  const [colorShade, setColorShade] = useState<keyof typeof grey>(500);

  const colorToUse = (typeof color === 'string') ? color : color[colorShade];

  return (
    <Polygon
      pathOptions={{ color: colorToUse, weight: 2, stroke: showBorders, fillOpacity: 0.3 }}
      positions={multiPolygon.coordinates.map((p: any) => p.map((c: any) => c.map((v: any) => v.x ? new LatLng(v.x as number, v.y as number) : new LatLng(v[1] as number, v[0] as number))))}
      eventHandlers={{
        mouseover: () => {
          setColorShade(700);
        },
        mouseout: () => {
          setColorShade(500);
        }
      }}
    >
      {popup && (
        <Popup maxWidth={600} minWidth={popupMinSize !== undefined ? popupMinSize : 600} maxHeight={600} autoPan>{popup}</Popup>
      )}
      <Tooltip>{tooltip}</Tooltip>
    </Polygon>
  );
}

export default InteractiveMultiPolygone;

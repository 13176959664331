import ApiService from './Api';
import ProvinceModel from '../models/Province';

class Province {
  getAllProvincesOfCountry(country: string): Promise<ProvinceModel[]> {
    return ApiService.get(`/provinces/${country}`);
  }
}

export default new Province();

import {
  makeStyles,
  createStyles,
  Box,
} from '@material-ui/core';

import PrecipitationService from '../services/Precipitation';
import DroughtChart from "../components/DroughtChart";
import { useEffect, useState } from 'react';
import moment from 'moment';
import useCountry from '../hooks/country';
import TriggerDot from './TriggerDot';
import { Typography } from '@mui/material';

const useStyles = makeStyles((theme) =>
  createStyles({
    flex: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-end',
      width: '100%',
      height: '300px'
    },
  }),
);

interface Props { provinceId: string }

function DroughtMapPopup({ provinceId }: Props) {
  const classes = useStyles();
  const [precipitations, setPrecipitations] = useState<{ month: number, year: number, value: number }[] | undefined>(undefined);
  const [triggers, setTriggers] = useState<{ red: number, orange: number, yellow: number }>();
  const { country } = useCountry();

  useEffect(() => {
    PrecipitationService.getMock(provinceId, country, 'drought').then(data => {
      const res: { month: number, year: number, value: number }[] = [];
      setTriggers(data.triggers);
      data.precipitations.forEach(precipitation => {
        const monthPrecipitation = res.find(p => p.month === moment(precipitation.date).get('month') && p.year === moment(precipitation.date).get('year'));
        if (monthPrecipitation) {
          monthPrecipitation.value = monthPrecipitation.value + parseFloat(precipitation.value);
        } else {
          res.push({ month: moment(precipitation.date).get('month'), year: moment(precipitation.date).get('year'), value: parseFloat(precipitation.value) });
        }
      });
      setPrecipitations(res);
    },
      err => { console.log(err) }
    );
  }, [provinceId]);

  return (
    <Box className={classes.flex}>
    <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%'}}>
      <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-evenly', width: '30%'}}>
        <TriggerDot level={0}/>
        <Typography>-</Typography>
        <Typography>10%</Typography>
        <Typography>-</Typography>
        <Typography>{triggers ? triggers.yellow : '-'} mm</Typography>
      </Box>
      <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-evenly', width: '30%'}}>
        <TriggerDot level={1}/>
        <Typography>-</Typography>
        <Typography>15%</Typography>
        <Typography>-</Typography>
        <Typography>{triggers ? triggers.orange : '-'} mm</Typography>
      </Box>
      <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-evenly', width: '30%'}}>
        <TriggerDot level={2}/>
        <Typography>-</Typography>
        <Typography>20%</Typography>
        <Typography>-</Typography>
        <Typography>{triggers ? triggers.red : '-'} mm</Typography>
      </Box>
    </Box>
      <DroughtChart
        data={precipitations || []}
        yellow_trigger={triggers ? triggers.yellow : 3}
        orange_trigger={triggers ? triggers.orange : 2}
        red_trigger={triggers ? triggers.red : 1}
      />
    </Box>
  );
}

export default DroughtMapPopup;